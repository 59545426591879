import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SprDashboardWidgets, SprGraphVisibilityConfiguration, SprWidget, SprWidgetDetails } from '../models/spr/configuration-spr';
import { restEndPoint } from '../core-constants.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { HttpParams } from '@angular/common/http';
import { GraphOverrides, SprDashboardFilter, SprFilter } from '../models/spr/filter/spr-filter';

@Injectable({providedIn: 'root'})
export class SprConfigurationWidgetRestService {
  private loadSubmittedWidgetsRestName = restEndPoint + 'spr/graph/submittedWidgets';
  private loadWidgetsRestName = restEndPoint + 'spr/graph/dashboard';
  private loadWidgetDetailsWithFilterRestName = restEndPoint + 'spr/graph/details';
  private saveWidgetsRestName = restEndPoint + 'spr/graph/save';
  private deleteWidgetRestName = restEndPoint + 'spr/graph/delete';
  private widgetOrderRestName = restEndPoint + 'spr/graph/saveOrder';
  private widgetVisibilityRestName = restEndPoint + 'spr/graph/saveVisibility';
  private getWidgetOrderRestName = restEndPoint + 'spr/graph/getWidgetOrder';
  private basedOnQuantity = restEndPoint + 'spr/based-on-quantity/:id';

  constructor(private http: CacheableHttpClient) {
  }

  loadCustomerSubmittedWidgets(): Observable<boolean> {
    return this.http.get(this.loadSubmittedWidgetsRestName, {cache: false});
  }

  loadDashboardWidgetsWithFilter(filter: SprDashboardFilter): Observable<SprDashboardWidgets> {
    return this.http.post<SprDashboardWidgets>(this.loadWidgetsRestName, filter);
  }

  loadWidgetDetailsWithFilter(widgetId: number, applyFilter: SprFilter,
    detailsType?: string, graphOverrides?: GraphOverrides): Observable<SprWidgetDetails> {
    return this.http.post<SprWidgetDetails>(this.loadWidgetDetailsWithFilterRestName, {
      graphId: widgetId,
      filter: applyFilter,
      detailsType,
      graphOverrides
    });
  }

  saveGraphConfiguration(widget: SprWidget): Observable<any> {
    return this.http.post(this.saveWidgetsRestName, widget);
  }

  deleteSprWidget(widgetId: number): Observable<any> {
    return this.http.delete(this.deleteWidgetRestName, {params: new HttpParams().set('id', widgetId.toString())});
  }

  saveWidgetOrder(order: string[]): Observable<any> {
    return this.http.post(this.widgetOrderRestName, order);
  }

  saveWidgetVisibility(visibilityWidget: SprGraphVisibilityConfiguration) {
    return this.http.post<SprGraphVisibilityConfiguration>(this.widgetVisibilityRestName, visibilityWidget);
  }

  getWidgetOrder(): Observable<string[]> {
    return this.http.get(this.getWidgetOrderRestName,  {cache: false});
  }

  checkBaseOnQuantity(quantityId: string): Observable<string> {
    return this.http.get<string>(this.basedOnQuantity.replace(/:id/g, quantityId), {cache: false});
  }
}
