import { DropdownOptions } from '../core/models/dropdown-options';

export enum SprFilterTypes {
  MODALITY_CODES = 'modalityCodes',
  CUSTOMERS = 'customers',
  EQUIPMENTS = 'equipments',
  OTHER_VALUES = 'otherValues',
  KPI = 'kpi',
}

export enum TimeSpan {
  ONE_MONTH = '1month',
  THREE_MONTHS = '3months',
  TWELVE_MONTHS = '12months',
  YEAR_TO_DATE = 'YTD'
}

export const timeSpanOptions: DropdownOptions[] = [
  {title: 'LABEL_1_MONTH', value: TimeSpan.ONE_MONTH},
  {title: 'LABEL_3_MONTHS', value: TimeSpan.THREE_MONTHS},
  {title: 'LABEL_12_MONTHS', value: TimeSpan.TWELVE_MONTHS},
  {title: 'LABEL_YEAR_TO_DATE', value: TimeSpan.YEAR_TO_DATE}
];

export enum GraphVisibilityStatus {
  SUBMITTED_EXTERNAL = 'submitted_external',
  SUBMITTED_INTERNAL = 'submitted_internal',
  SIMULATED = 'simulated'
}

export const visibilityStatusOptions: DropdownOptions[] = [
  {title: 'LABEL_SPR_SUBMITTED_EXTERNAL', value: GraphVisibilityStatus.SUBMITTED_EXTERNAL},
  {title: 'LABEL_SPR_SUBMITTED_INTERNAL', value: GraphVisibilityStatus.SUBMITTED_INTERNAL},
  {title: 'LABEL_SPR_SIMULATED', value: GraphVisibilityStatus.SIMULATED}
];

export const isTimeSpan = (timeSpan: string): boolean =>
  Object.keys(TimeSpan).some(key => TimeSpan[key] === timeSpan);
